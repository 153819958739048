import axios from "axios";
import { BASE_API } from "../../config/api";
import moment from "moment";
export const getInvoiceByUserIdAsync = async (id, filter, pagination) =>
  await axios
    .post(`${BASE_API}admin/user/${id}/invoice`, {
      filter: filter,
      pagination,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getInvoiceByProjectIdAsync = async (id, filter, pagination) =>
  await axios
    .post(`${BASE_API}admin/project/${id}/invoice`, {
      filter: filter,
      pagination,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const generateInvoices = async () =>
    await axios
      .get(`${BASE_API}invoice/generate`)
      .then((response) => response && response.data)
      .catch((error) => error.message);

export const generateInvoicesTimeframe = async (from, to) => {
    let getParams = '';
    if (from) {
        getParams += 'from=' + moment(from).utcOffset(0, true).format() + '&';
    }
    if (to) {
        getParams += 'to=' + moment(to).utcOffset(0, true).format();
    }
    return await axios
        .get(`${BASE_API}invoice/generate?${getParams}`)
        .then((response) => response && response.data)
        .catch((error) => error.message);
}

export const generateInvoicesTimeframeForUser = async (userId, from, to) => {
    let getParams = '';
    if (from) {
        getParams += 'from=' + moment(from).utcOffset(0, true).format() + '&';
    }
    if (to) {
        getParams += 'to=' + moment(to).utcOffset(0, true).format();
    }
    return await axios
        .get(`${BASE_API}invoice/generate/${userId}?${getParams}`)
        .then((response) => response && response.data)
        .catch((error) => error.message);
}

export const generateInvoicesById = async (userId) =>
    await axios
      .get(`${BASE_API}invoice/generate/${userId}`)
      .then((response) => response && response.data)
      .catch((error) => error.message);

export const getAllInvoicesAsync = async (filter, pagination) =>
  await axios
    .post(`${BASE_API}admin/invoice/getAll`, {
      filter: filter,
      pagination,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const updateInvoiceAsync = async (id, isPaid) =>
  await axios
    .patch(`${BASE_API}admin/invoice/${id}`, {
      isPaid,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const updateInvoicesByIdsAsync = async (status, ids) =>
  await axios
    .patch(`${BASE_API}admin/invoice/update/batch`, {
      status,
      invoice_ids: ids,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getInvoiceByIdAsync = async (id) =>
  await axios
    .get(`${BASE_API}user/invoice/${id}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const invoiceToCsvAsync = async (data) =>
  await axios
    .post(`${BASE_API}admin/invoice/to/csv`, {
      invoice_ids: data,
    })
    .then((response) => {
      const csv = response && response.data;
      const pom = document.createElement("a");
      const csvContent = csv;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute(
        "download",
        `pw_invoice_export_${moment().format("DD-MM-YYYY")}.csv`
      );
      pom.click();
    })
    .catch((error) => error.message);
